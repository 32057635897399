export const menuItems = [
    {
        label: "menuitems.menu.text",
        isTitle: true
    },
    {
        label: 'menuitems.dashboard.text',
        icon: 'ri-dashboard-line',
        badge: {
            variant: "success",
            text: "menuitems.dashboard.badge"
        },
        link: '/'
    },
    {
        label: 'menuitems.admin.text',
        icon: 'ri-admin-line',
        subItems: [
            {
                label: 'menuitems.admin.list',
                link: '/admin',
            },
            {
                label: 'menuitems.admin.add',
                link: '/admin/form/add'
            }
        ]
    },
    {
        label: 'menuitems.agency.text',
        icon: 'ri-building-2-line',
        // link: '/agency'
        subItems: [
            {
                label: 'menuitems.agency.action.list',
                link: '/agencies',
            },
            {
                label: 'menuitems.agency.action.add',
                link: '/agency/form/add'
            },
            // {
            //     label: 'menuitems.agency.action.payout',
            //     link: '/agency/payout'
            // }
        ]
    },
    {
        label: 'menuitems.agent.text',
        icon: 'ri-team-line',
        // link: '/agent',
        subItems: [
            {
                label: 'menuitems.agent.action.list',
                link: '/agent',
            },
            {
                label: 'menuitems.agent.action.add',
                link: '/agent/form/add'
            }
        ]
    },
    {
        label: 'menuitems.member.text',
        icon: ' ri-user-line',
        subItems: [
            {
                label: 'menuitems.member.list',
                link: '/member',
            },
            {
                label: 'menuitems.member.add',
                link: '/member/add'
            },
            // {
            //     label: 'menuitems.member.points',
            //     link: '/member-points'
            // }
        ]
    },
    {
        label: 'menuitems.tenancy.text',
        icon: ' ri-home-line',
        link: "",
        subItems: [
            {
                label: 'menuitems.tenancy.list',
                link: '/tenancy',
            },
            {
                label: 'menuitems.tenancy.add',
                link: '/tenancy/add-tenancy',
            },
        ]
    },
    {
        label: 'menuitems.ctc.text',
        icon: 'ri-file-copy-2-line',
        subItems: [
            {
                label: 'menuitems.ctc.list',
                link: '/ctc'
            },
            {
                label: 'menuitems.ctc.add',
                link: '/ctc/create-ctc'
            }
        ]
    },  

    {
        label: 'menuitems.amenities.text',
        icon: ' ri-fridge-line',
        subItems: [
            {
                label: 'menuitems.amenities.list',
                link: '/amenity'
            },
            {
                label: 'menuitems.amenities.add',
                link: '/amenity/form/add'
            }
        ]
    },
    
    {
        label: 'menuitems.agreement.text',
        icon: 'ri-pen-nib-line',
        link: '/agreement/editor'
    },
    
    // {
    //     label: 'menuitems.inventory.text',
    //     icon: ' ri-folder-reduce-fill',
    //     subItems: [
    //         {
    //             label: 'menuitems.inventory.list',
    //             link: '/inventory',
    //         },
    //         {
    //             label: 'menuitems.inventory.add',
    //             link: '/inventory/form/add'
    //         }
    //     ]
    // },
    // {
    //     label: 'menuitems.ticket.text',
    //     icon: ' ri-ticket-2-line',
    //     subItems: [
    //         {
    //             label: 'menuitems.ticket.list',
    //             link: '/ticket',
    //         },
    //         {
    //             label: 'menuitems.ticket.add',
    //             link: '/ticket/form/add'
    //         }
    //     ]
    // },
    // {
    //     label: 'menuitems.report.text',
    //     icon: 'ri-file-chart-2-fill',
    //     link: '/report'
    // },
    {
        label: 'menuitems.setting.text',
        icon: 'ri-settings-3-line',
        subItems: [
            {
                label: 'menuitems.general.text',
                link: '/setting/general'
            },
            {
                label: 'menuitems.setting.list.payment_method',
                link: '/setting/payment-methods'
            },
            {
                label: 'menuitems.setting.list.role',
                link: '/setting/role'
            },
                // {
                //     label: 'menuitems.amenities.text',
                //     link: '/amenity'
                // },
        ]
    },
]